<template>
    <div>
        <section class="section">
            <div class="container-fluid w-75">
                <div class="row">
                    <div class="col-12">
                        <h1 class="text-center">Whale Activities</h1>
                        <h2 class="text-center">Observez les flux financiers des baleines crypto</h2>

                        <p class="text-center timeframe mt-5 mb-5">
                            <span>Fenêtre de temps: </span>
                            <a href="2s" :class="{ 'active': this.timeframe=='2s' }" @click="setTimeFrame(2, 'week', $event)">2s</a>
                            <a href="1s" :class="{ 'active': this.timeframe=='1s' }" @click="setTimeFrame(2, 'week', $event)">1s</a>
                            <a href="1j" :class="{ 'active': this.timeframe=='1j' }" @click="setTimeFrame(1, 'day', $event)">1j</a>
                            <a href="12h" :class="{ 'active': this.timeframe=='12h' }" @click="setTimeFrame(12, 'hour', $event)">12h</a>
                            <a href="4h" :class="{ 'active': this.timeframe=='4h' }" @click="setTimeFrame(4, 'hour', $event)">4h</a>
                            <a href="1h" :class="{ 'active': this.timeframe=='1h' }" @click="setTimeFrame(1, 'hour', $event)">1h</a>
                        </p>

                        <div class="row">
                            <h3>Résumé des mouvements de cryptomonnaies (hors stablecoins)</h3>
                        </div>
                        <div class="row text-center" style="margin-bottom: 40px;">
                            <div class="col-2 col-unkown">
                                <h3>Unkown</h3>
                            </div>
                            <div class="col-8 col-activities">
                                <div>
                                    <p class="arrow-right">
                                        <span class="text-primary">{{ human(toExchanges(false)) }}$</span> de <span class="text-success">Cryptomonnaies</span>
                                    </p>
                                    <p class="arrow-left">
                                        <span class="text-primary">{{ human(toWallet(false)) }}$</span> de <span class="text-success">Cryptomonnaies</span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-2 col-exchanges">
                                <h3>Exchanges</h3>
                            </div>
                        </div>

                        <div class="row">
                            <h3>Résumé des mouvements des stablecoins</h3>
                        </div>
                        <div class="row text-center" style="margin-bottom: 40px;">
                            <div class="col-2 col-unkown">
                                <h3>Unkown</h3>
                            </div>
                            <div class="col-8 col-activities">
                                <div>
                                    <p class="arrow-right">
                                        <span class="text-primary">{{ human(toExchanges(true)) }}$</span> de <span class="text-success">Stablecoins</span>
                                    </p>
                                    <p class="arrow-left">
                                        <span class="text-primary">{{ human(toWallet(true)) }}$</span> de <span class="text-success">Stablecoins</span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-2 col-exchanges">
                                <h3>Exchanges</h3>
                            </div>
                        </div>

                        <div class="row">
                            <h3>Détails des mouvements</h3>
                        </div>

                        <div class="row text-center" style="margin-bottom: 40px;">
                            <div class="col-2 col-unkown">
                                <h3>Unkown</h3>
                            </div>
                            <div class="col-8 col-activities">
                                <div v-for="activity in activities" :key="activity.hash">
                                    <p :class="{ 'arrow-left': activity.fromOwnerType == 'exchange', 'arrow-right': activity.toOwnerType == 'exchange' }">
                                        <span class="text-primary">{{ human(activity.amountUSD) }}$</span> de <span class="text-success">{{ activity.symbol.toUpperCase() }}</span>
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div class="col-2 col-exchanges">
                                <h3>Exchanges</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style>
.timeframe a {
    margin-left: 10px;
    margin-right: 10px;
}

.timeframe a.active {
    color: #445270;
    text-decoration: underline;
}

.col-unkown {
    padding-top: 20px;
    background: #ccc;
}

.col-exchanges {
    padding-top: 20px;
    background: #ccc;
}

.col-activities {
    padding-top: 80px;
    padding-left: 0px;
    padding-right: 0px;
}

.arrow-right,
.arrow-left {
    position: relative;
    background: #ccc;
    width: calc(100% - 60px);
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
}

.arrow-left {
    background-color: #d3f5b0;
}

.arrow-right {
    background-color: #f5b7b0;
}

.arrow-right:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(204, 204, 204, 0);
    border-left-color: #f5b7b0;
    border-width: 30px;
    margin-top: -30px;
}

.arrow-right:before {
    right: 100%;
    top: 30px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 30px;
    position: absolute;
    pointer-events: none;
    border-color: rgba(204, 204, 204, 0);
    border-left-color: #f5b7b0;
    border-right-color: #f5b7b0;
    border-top-color: #f5b7b0;
    border-bottom-color: #f5b7b0;
    border-width: 12px;
    margin-top: -30px;
}

.arrow-left:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(204, 204, 204, 0);
    border-right-color: #d3f5b0;
    border-width: 30px;
    margin-top: -30px;
}

.arrow-left:before {
    left: 100%;
    top: 30px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 30px;
    position: absolute;
    pointer-events: none;
    border-color: rgba(204, 204, 204, 0);
    border-left-color: #d3f5b0;
    border-right-color: #d3f5b0;
    border-top-color: #d3f5b0;
    border-bottom-color: #d3f5b0;
    border-width: 12px;
    margin-top: -30px;
}
</style>

<script>
import { mapMutations } from "vuex";
import { toHumanString } from "human-readable-numbers";
import axios from "axios";
import _ from "lodash";

export default {
    data() {
        return {
            timeframe: "1h"
        };
    },
    methods: {
        human(number) {
            return toHumanString(parseFloat(number));
        },

        toExchanges(wantStableCoin = false) {
            let sum = 0;
            for (let activity of this.activities) {
                if (this.isStableCoin(activity.symbol) === wantStableCoin) {
                    if (
                        activity.fromOwnerType === "unknown" &&
                        activity.toOwnerType === "exchange"
                    ) {
                        sum += parseFloat(activity.amountUSD);
                    }
                }
            }
            return sum;
        },

        toWallet(wantStableCoin = false) {
            let sum = 0;
            for (let activity of this.activities) {
                if (this.isStableCoin(activity.symbol) === wantStableCoin) {
                    if (
                        activity.fromOwnerType === "exchange" &&
                        activity.toOwnerType === "unknown"
                    ) {
                        sum += parseFloat(activity.amountUSD);
                    }
                }
            }
            return sum;
        },

        isStableCoin(symbol) {
            return ["USDT", "USDC", "BUSD", "DAI", "PAX"].includes(
                symbol.toUpperCase()
            );
        },

        async setTimeFrame(units, unitName, e) {
            this.timeframe = $(e.target)
                .attr("href")
                .replace("#", "");

            let json = await axios.get(
                `/api/whaleactivity/${units}/${unitName}`
            );
            this.activities = json.data.activities;
        }
    },
    async asyncData({ store, $axios, route }) {
        const slug = _.get(route, ["params", "slug"]) || "1h";
        const apiParams = {
            "1h": "/1/hour",
            "4h": "/4/hour",
            "12h": "/12/hour",
            "1j": "/1/day",
            "1s": "/1/week",
            "2s": "/2/week"
        };
        const timeframe = apiParams[slug];
        const json = await $axios.get("/api/whaleactivity" + timeframe);

        let activities = json.data.activities;

        return {
            activities: activities,
            timeframe: slug
        };
    }
};
</script>
